import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

import Button from '../Button';
import { Container, Content, WrapperButton } from './styles';

const Main = () => {
  return (
    <Container>
      <Content>
        <WrapperButton>
          <AnchorLink to="/#form-petshop-register">
            <Button height={60} width={700} styling="landingPageRegister" type="submit">
              Seja já nosso parceiro
            </Button>
          </AnchorLink>
        </WrapperButton>
      </Content>
    </Container>
  );
};

export default Main;

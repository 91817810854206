import React from 'react';

import FormGroup from './Form';

import Pets from '../../../assets/images/pets.svg';
import { StaticImage } from "gatsby-plugin-image";
import {
  Container,
  Content,
  Info,
  Form,
  Title,
  SubText,
  Text,
  Box,
  ContentImg,
  Img,
  FormTitle,
  VideoContent
} from './styles';
import { VideoPlayer } from '../../VideoPlayer';

const Main = () => (
  <section>
    <Container id="main">
      <Content>
        <Info>
          <Title>
            <p>O Marketplace das Petshops.</p>
          </Title>
          <ContentImg>
            {/* <Img src={Pets} alt="" /> */}
            <StaticImage 
            src="../../../assets/images/pets.svg" 
            alt="Pets" 
            placeholder="blurred"
            style={{marginBottom: 0}}
          />
          </ContentImg>
          <VideoContent>
            <p>Veja como é fácil se tornar um parceiro animAU</p>
            <VideoPlayer/>
          </VideoContent>
        </Info>
        <Form id="form-petshop-register">
          <FormTitle>
            Para a sua PETSHOP, CLÍNICA veterinária ou VOCÊ prestador de serviços Pet
          </FormTitle>
          <Text>
            <li>
              Divulgue seus produtos e serviços na animAU e venha prosperar no 2º maior mercado de
              Pets do mundo.
            </li>
            <li>
              Os 100 primeiros parceiros a se cadastrarem receberão 50% de desconto nas taxas de
              vendas por 3 meses! O retorno será realizado via cashback ao término de cada mês do
              exercício.
            </li>
          </Text>
          <Box>
            <FormGroup />
            <SubText>
              Ao prosseguir, aceito que a animAU entre em contato comigo por telefone, e-mail ou
              Whatsapp (incluindo mensagens automáticas para fins comerciais).
            </SubText>
          </Box>
        </Form>
      </Content>
    </Container>
  </section>
);

export default Main;

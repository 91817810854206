import styled from 'styled-components';

export const Nav = styled.nav`
  background-color:  ${props => (props.stylingScroll ? 'rgba(56, 28, 79, 0.99)' : 'rgba(56, 28, 79, 0.75)')};
  height: 90px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;
  position: ${props => (props.stylingScroll ? 'fixed' : 'sticky')};
  padding: 30px;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: 768px) {
    height: 75px;
  }
`;

export const Content = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 0 24px;
  z-index: 1;
`;

export const Img = styled.img`
  margin-bottom: 0;
`;

export const MobileIcons = styled.div`
  display: none;
  color: #fff;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    margin-top: -10px;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  text-align: center;
  margin: unset;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  margin-right: 22px;
`;

export const NavLinks = styled.a`
  color: #fbc02d;
  font-style: normal;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 1rem;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;

import { cpf, cnpj } from 'cpf-cnpj-validator';

export const validaCpfCnpj = val => {
  let valorFormatado = val.replace('-', '');
  valorFormatado = valorFormatado.replace('.', '');
  valorFormatado = valorFormatado.replace('/', '');
  valorFormatado = valorFormatado.replace('.', '');

  if (valorFormatado.length == 11) {
    return cpf.isValid(valorFormatado);
  }
  if (valorFormatado.length == 14) {
    return cnpj.isValid(valorFormatado);
  }
};

export const isCnpj = (val) => {
  if(!val) return 
  
  let valorFormatado = val.replace('-', '');
  valorFormatado = valorFormatado.replace('.', '');
  valorFormatado = valorFormatado.replace('/', '');
  valorFormatado = valorFormatado.replace('.', '');

  if (valorFormatado.length == 11) {
    return false
  }
  if (valorFormatado.length == 14) {
    return true
  }
}
import React, { useState, useCallback } from 'react';

import { Container, LabelInput, Content, ErrorMensage } from './styles';

const Select = ({ name, icon: Icon, type, options, error, height, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  
  return (
    <Container>
      <Content isFilled={isFilled} isFocused={isFocused} isErrored={!!error} height={height}>
        {rest.label && <LabelInput>{rest.label}</LabelInput>}
        <select onFocus={handleInputFocus} onBlur={handleInputBlur} {...rest}>
          {!rest.value && (
            <option key={0} value="">
              Selecione
            </option>
          )}
          {options &&
            options.map(option => (
              <option key={option.id} value={option.value}>
                {option.value}
              </option>
            ))}
        </select>
        {Icon && <Icon size={20} />}
      </Content>

      {error ? <ErrorMensage>{error}</ErrorMensage> : <br />}
    </Container>
  );
};

export default Select;

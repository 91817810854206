import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Item,
  ItemHeader,
  ItemTitle,
  IconArrowRight,
  IconArrowDown,
  ItemBody,
  ItemContent,
} from './styles';

const Card = ({ title, heightExpand, children }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <Item onClick={() => setActive(!active)} active={active} height={heightExpand}>
        <ItemHeader>
          <ItemTitle>{title}</ItemTitle>
          {active ? <IconArrowDown /> : <IconArrowRight />}
        </ItemHeader>
        <ItemBody active={active}>
          <ItemContent>{children}</ItemContent>
        </ItemBody>
      </Item>
    </>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  heightExpand: PropTypes.string,
  children: PropTypes.element,
};

export default Card;

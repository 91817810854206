import React from 'react';
import AppImage from '../../../assets/images/lateral1.png';
import GooglePlayImage from '../../../assets/images/googleplay.png';

import {
  Container,
  Content,
  Info,
  Title,
  Text,
  SubTitle,
  Logo,
  DownloadContent,
  ContentImg,
  Img,
  LinkA,
} from './styles';

const Main = () => (
  <section>
    <Container id="download-section">
      <Content>
        <ContentImg>
          <Img src={AppImage} alt="Imagem meramente ilustrativa." />
          <div id="grad"></div>
        </ContentImg>
        <Info>
          <Title>
            <p>Encontre produtos e serviços com os melhores preços da sua região.</p>
          </Title>
          <Text>
            Baixe agora e compre rações, brinquedos e roupas ou agende banhos, tosas, vacinas e
            muito mais.
          </Text>
          <DownloadContent>
            <SubTitle>Baixe agora o app da animAU</SubTitle>
            <Logo>
              <LinkA
                href="https://play.google.com/store/apps/details?id=br.com.animau"
                target="_blank"
                rel="noreferrer"
                aria-label="Clique para baixar o aplicativo na play store."
              >
                <Img src={GooglePlayImage} alt="" />
              </LinkA>
            </Logo>
          </DownloadContent>
        </Info>
      </Content>
    </Container>
  </section>
);

export default Main;

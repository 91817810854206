import styled from 'styled-components';

import AboutImage from '../../../assets/images/about.png';

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding: 20px 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;

  background-image: url(${AboutImage});
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  max-width: 1350px;
  width: 100%;
`;

export const Title = styled.div`
  color: #fff;
  margin-left: 16px;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 3.7rem;
  line-height: 1.1;
  max-width: 1250px;

  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    font-weight: 700;
    margin-left: 0px;
  }
`;
export const SubTitle = styled.div`
  color: #fff;
  margin-left: 16px;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 3rem;
  font-weight: 450;
  text-rendering: optimizeLegibility;
  font-size: 1.9rem;
  line-height: 1.1;
  max-width: 1250px;

  @media screen and (max-width: 768px) {
    font-size: 1.7rem;
    margin-left: 0px;
    font-weight: 400;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-left: 20px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    margin-left: 0px;
    margin-bottom: -32px;

  }
`;

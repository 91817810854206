import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FaBars } from 'react-icons/fa';

import Button from '../Button';
import { Nav, Content, Img, MobileIcons, NavMenu, NavItem, NavLinks } from './styles';

import Logo from "../../assets/images/logo-horizontal-branco.svg"
const Header = ({ toggle }) => {
  const [navbarScrolling, setNavbarScrolling] = useState(false);

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.addEventListener('scroll', () => {
      if (window.scrollY >= 40) {
        setNavbarScrolling(true);
      } else {
        setNavbarScrolling(false);
      }
    });
  }, []);

  return (
    <Nav stylingScroll={navbarScrolling}>
      <Content>
        <Link to="/" aria-label="Clique aqui para voltar para a home.">
          <Logo 
            style={{marginBottom: 0}}
            width="200"
          />
        </Link>
        <MobileIcons onClick={toggle}>
          <FaBars />
        </MobileIcons>
        <NavMenu>
          <NavItem>
            <NavLinks
              href="#main"
            >
              Home
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              href="#about-section"
            >
              Vantagens
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              href="#download-section"
            >
              Download
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              href="#questions-section"
            >
              Perguntas
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              href="https://play.google.com/store/apps/details?id=br.com.animau"
              target="_blank"
              aria-label="Clique para baixar o aplicativo."
              rel="noreferrer"
            >
              Baixe Nosso Aplicativo
            </NavLinks>
          </NavItem>
          <NavItem>
            <Link to="https://app.animaupet.com.br/" aria-label="Clique para ir para o website.">
              <Button height={42} width={170} styling="landingPage">
                Já Sou Parceiro
              </Button>
            </Link>
          </NavItem>
        </NavMenu>
      </Content>
    </Nav>
  );
};

Header.propTypes = {
  toggle: PropTypes.func,
};

export default Header;

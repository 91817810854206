export default function maskCpfAndCnpj(documento) {
  let parsedDocumento = '';
  // Remove tudo o que não é dígito
  parsedDocumento = documento.replace(/\D/g, '');

  if (parsedDocumento.length <= 13) {
    // CPF

    // Coloca um ponto entre o terceiro e o quarto dígitos
    parsedDocumento = parsedDocumento.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    parsedDocumento = parsedDocumento.replace(/(\d{3})(\d)/, '$1.$2');

    // Coloca um hífen entre o terceiro e o quarto dígitos
    parsedDocumento = parsedDocumento.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    // CNPJ

    // Coloca ponto entre o segundo e o terceiro dígitos
    parsedDocumento = parsedDocumento.replace(/^(\d{2})(\d)/, '$1.$2');

    // Coloca ponto entre o quinto e o sexto dígitos
    parsedDocumento = parsedDocumento.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

    // Coloca uma barra entre o oitavo e o nono dígitos
    parsedDocumento = parsedDocumento.replace(/\.(\d{3})(\d)/, '.$1/$2');

    // Coloca um hífen depois do bloco de quatro dígitos
    parsedDocumento = parsedDocumento.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return parsedDocumento;
}

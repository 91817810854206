import styled from 'styled-components';
import { Link as LinkG } from 'gatsby';

import { FaTimes } from 'react-icons/fa';

export const Container = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #381c4f;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px;
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Img = styled.img`
  position: absolute;
  top: 6rem;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  font-weight: 300;
  cursor: pointer;
  outline: none;
`;

export const Content = styled.div`
  color: #fff;
`;

export const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 460px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const Item = styled.li`
  margin-right: 22px;
`;

export const LinksSideBar = styled(LinkG)`
  color: #fbc02d;
  font-style: normal;
  line-height: 20px;
  font-weight: 500;
  font-size: 20px;
  padding: 0 1rem;
  margin-top: 8px;

  &:hover {
    color: #fff;
    transition: 0.2s ease-in-out;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
    max-width: 800px;
    position: relative;
    
    video {
        width: 100%;
        height: auto;
        border-radius: .5rem;
    }

    .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: .5rem 1rem;
        background: rgba(255, 255, 255, 0.5);
        border-radius: .5rem;
        border: 1px solid #472465;
        cursor: pointer;
        transition: filter .2s;
        
        &:hover {
            filter: brightness(.9);
        }

        &:active {
            transform: translate(-48%, -48%);

        }

        svg {
            cursor: pointer;
            zoom: 3;

            path {
                fill: #472465;
            }
        }
        

    }
`
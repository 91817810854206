import styled, { css } from 'styled-components';

import ArrowIconSelectPurple from '../../assets/icons/ic-direction-down.svg';

export const Container = styled.div`
  width: 100%;
`;

export const ErrorMensage = styled.p`
  font-size: 0.8rem;
  color: #c53030;
`;
export const Content = styled.div`
  /* font-family: 'Montserrat'; */
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  color: #653391;
  display: flex;
  align-items: center;
  height: ${props => (props.height ? props.height : '55px')};
  position: relative;
  width: 100%;

  margin-top: 0 !important;

  ${props =>
    props.isErrored &&
    css`
      border: 2px solid #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      color: #1d211c;
      border: 2px solid #8c4300;
    `}
  ${props =>
    props.isFilled &&
    css`
      color: #653391;
    `}

  select {
    flex: 1;
    width: 100px;
    background: transparent;
    border: 0;
    margin-top: 20px;

    padding: 5px 0;
    font-size: 16px;
    /* font-family: 'Montserrat'; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(${ArrowIconSelectPurple}) 24px no-repeat;
    background-position: top right, center;
    color: #1D211C;
    font-size: 1.2rem;
    line-height: 1.25rem;
    &::placeholder {
      color: #1D211C;
      
    }
    option {
      color: #5b636f;
    }
    overflow: hidden;
  }

  svg {
    margin-left: 15px;
  }
`;

export const LabelInput = styled.div`
  color: #5b636f;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.0015em;
  position: absolute;
  top: 5px;
`;

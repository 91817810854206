import React from 'react';

import Card from './Card';
import { Container, Content, Title, SubTitle, Cards } from './styles';

const Main = () => (
  <section>
    <Container id="questions-section">
      <Content>
        <Title>Perguntas Frequentes</Title>
        <SubTitle>
          <p>Tire suas dúvidas sobre a Animau e faça parte do marketplace das petshops.</p>
        </SubTitle>
        <Cards>
          <Card title="Como eu faço para colocar a minha loja na animAU?" heightExpand="450px">
            <p>Isso é bem ágil e você deve seguir o passo a passo:</p>
            <ul>
              <li>
                Inicialmente, cadastre os dados do seu estabelecimento comercial na plataforma, como
                usuário, e-mail, endereço, conta bancária, modelo de entrega que deseja utilizar.
              </li>
              <li>
                Após a inserção desses dados, você terá acesso ao Dashboard animAU. Nele você
                cadastrará os seus produtos e serviços que ficarão disponíveis para aquisição dos
                seus clientes.
              </li>
              <li>
                Pronto, após esse passo já estamos prontos para divulgar a sua loja na Animau!
              </li>
            </ul>
          </Card>
          <Card
            title="Como eu recebo os pagamentos das minhas vendas na animAU? "
            heightExpand="280px"
          >
            <p>
              O valor das suas vendas de produtos e serviços feitos na plataforma animAU ficarão
              disponíveis em uma conta animAU. Ao ser disponibilizado o crédito você poderá fazer a
              transferência para a conta no Banco indicado no seu cadastro inicial da animAU.
            </p>
          </Card>
          <Card
            title="Qual a taxa ou mensalidade que pago para estar na animAU? "
            heightExpand="auto"
          >
            <p> Não é cobrada mensalidade para a divulgação do seu Pet shop, Clínica Veterinária ou dos seus serviços no segmento PET. </p>
            <p> Em cima das vendas realizadas pela animAU será cobrada a taxa conforme tabela abaixo, já incluído as taxas do pagamento on line. </p>
            <ul style={{margin: '1rem 0'}}>
                <li><strong>1º ao 6º mês: 6,5%</strong> mais 3,5%  do pagamento online = <strong>10%</strong></li>
                <li><strong>Após esse período: 11,5%</strong> mais 3,5% do pagamento online = <strong>15%</strong></li>
              </ul>
            
            <p>O seu recebimento será realizado em até 30 dias após a conclusão da venda.</p>
          </Card>
        </Cards>
      </Content>
    </Container>
  </section>
);

export default Main;

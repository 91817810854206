import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
  max-width: 300px;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
`;
export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
`;

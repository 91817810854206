import React, { useState, useRef } from 'react';

import { Container } from './styles';

import ICPlay from '../../assets/icons/ic-play.svg';

export function VideoPlayer() {
    const video = useRef(null)
    const [onPlay, setOnPlay] = useState(false)

    function handlePlay() {
        if(video) {
            setOnPlay(true)
            video.current.play()
        }
    }

    return(
        <Container>
            <video 
                ref={video}
                src={'https://firebasestorage.googleapis.com/v0/b/ipetis-b8f49.appspot.com/o/assets%2Fvideos%2Fpasso-a-passo-parceiros.mp4?alt=media&token=3e8bd3d3-6f41-45c6-9418-93ac48eefd62'} 
                controls={onPlay}
                autoPlay={false}
            />
            {!onPlay &&
            <div onClick={handlePlay} className="btn-play">
                <ICPlay />

            </div>}
        </Container>
        
    )
}
import styled from 'styled-components';

export const Footer = styled.footer`
  background-color: #472465;
  padding: 18px 0 18px 0;
  height: 100%;
  max-height: 104px;

  @media screen and (max-width: 768px) {
    max-height: none;
  }
`;
export const ContentFooter = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-height: none;
  }
`;

export const Img = styled.img`
  margin-bottom: 0;
  margin-right: 25px;
  max-width: ${({ width }) => width || '100px'};
  color: #fff;
  aspect-ratio: attr(width) / attr(height);
  width:100%;
  height: auto;
`;

export const WrapperDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 10px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;

    ${Img} {
      margin-top: 12px;
      align-items: center;
    }
  }
`;
export const WrapperEmail = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -24px;
  width: auto;

  p {
    color: #fff;
    font-weight: 600;
    padding-bottom: .5rem;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
    width: 50%;
    align-self: center;
  }

  a {
    color: #fff;
    font-weight: 400;
    font-size: .9rem;
    margin-bottom: .6rem;
    padding-bottom: 2px;
    border-bottom: 1px solid #7C52A1;
    width: 100%;
    ${Img} {
      margin-right: 5px;
    }
  }
`;


export const WrapperSocialMedias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 20px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
  }
`;

export const GroupImg = styled.div`
  margin-top: -5px;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
`;

export const SubFooter = styled.div`
  background-color: #381c4f;
  padding: 16px 0 16px 0;
  height: 100%;
  span {
    padding: 12px 16px 12px 16px;
  }
`;
export const ContentSubFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
  }
`;

import styled from 'styled-components';
import MainImage from '../../../assets/images/sectionBackground.png';

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  background-image: url(${MainImage});
  background-size: auto;
  background-position: 19% 48%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    background-image: url(${MainImage});
    background-size: auto;
    background-position: 19% 8%;
    background-repeat: no-repeat;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;

  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-right: 30px;
  }
`;
export const ContentImg = styled.div`
  //padding: 10px;
  max-width: 513px;
  width: 100%;
  margin-left: -80px;
  position: relative;
  #grad {
    background-image: linear-gradient(to bottom left, rgba(101,51,144, 0), rgba(101,51,144, 0.7));
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: -24px;
  }
`;
export const Logo = styled.div`
  max-width: 180px;
  width: 100%;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
  margin-left: 24px;
`;

export const Info = styled.div`
  max-width: 653px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const Title = styled.div`
  padding: 10px;
  p {
    color: white;
    font-weight: normal;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1;
  }
  margin-bottom: 20px;
`;

export const Text = styled.div`
  color: #d9cce3;
  margin-left: 12px;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-size: 1.75rem;
  line-height: 1.1;
  max-width: 1250px;
`;
export const SubTitle = styled.div`
  color: #fff;
  margin-left: 12px;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-size: 1.95rem;
  line-height: 1.1;
  max-width: 1250px;
`;

export const DownloadContent = styled.div`
  margin-top: 30px;
`;

export const LinkA = styled.a``;

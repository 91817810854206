import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Button from '../../Button';

import LogoIpetis from '../../../assets/images/logo-horizontal-branco.svg';
import {
  Container,
  Header,
  Img,
  Icon,
  CloseIcon,
  Content,
  Menu,
  Item,
  LinksSideBar,
} from './styles';

function Sidebar({ toggle, isOpen }) {
  return (
    <Container isOpen={isOpen} onClick={toggle}>
      <Header>
        <Link to="/">
          <Img src={LogoIpetis} alt="" />
        </Link>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
      </Header>
      <Content>
        <Menu>
          <Item>
            <LinksSideBar to="https://play.google.com/store/apps/details?id=br.com.animau" aria-label="Clique para baixar o nosso aplicativo.">
              Baixe Nosso Aplicativo
            </LinksSideBar>
          </Item>
          <Item>
            <Link to="https://app.animaupet.com.br/">
              <Button height={54} width={200} styling="landingPage">
                Já Sou Parceiro
              </Button>
            </Link>
          </Item>
        </Menu>
      </Content>
    </Container>
  );
}

Sidebar.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Sidebar;

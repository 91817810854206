import React from 'react';
import { Link } from 'gatsby';

import {
  Footer,
  SubFooter,
  ContentFooter,
  ContentSubFooter,
  Img,
  WrapperDownload,
  WrapperEmail,
  WrapperSocialMedias,
  GroupImg,
} from './styles';

import LogoIpetis from '../../../assets/images/logo-horizontal-branco.svg';
import IcGooglePlay from '../../../assets/images/googleplay.png';
import IcFacebook from '../../../assets/icons/ic-social-medias/ic-facebook.png';
import IcLikedin from '../../../assets/icons/ic-social-medias/ic-linkedin.png';
import IcInstagram from '../../../assets/icons/ic-social-medias/ic-instagram.png';
import IcWpp from '../../../assets/icons/ic-social-medias/whatsapp.svg';

function About() {
  return (
    <>
      <Footer>
        <ContentFooter>
          <Link to="/">
            <Img src={LogoIpetis} alt="" width="160px" />
          </Link>
          <WrapperDownload>
            <span>Baixe o nosso aplicativo</span>
            <a
              href="https://play.google.com/store/apps/details?id=br.com.animau"
              target="_blank"
              rel="noreferrer"
              aria-label="Clique para baixar o aplicativo na play store."
            >
              <Img src={IcGooglePlay} alt="" width="115px" />
            </a>
          </WrapperDownload>
          <WrapperEmail>
            <p>Fale com a gente</p>
            <a
                href="https://wa.me/message/GRCUQG5ZXRAIA1"
                target="_blank"
                rel="noreferrer"
                aria-label="Clique falar conosco pelo whatsapp."
              >
                  No whatsapp <Img src={IcWpp} alt="" width="16px" />: (31) 8376-3665
            </a>
            <a href="mailto:contato@animaupet.com.br" target="_blank" rel="noreferrer" aria-label="Clique falar conosco pelo email.">
              No e-mail: contato@animaupet.com.br
            </a>
          </WrapperEmail>
          <WrapperSocialMedias>
            {/* <span>Nos siga nas redes socias</span> */}
            <GroupImg>
              <a
                href="https://www.linkedin.com/company/animaubrasil/"
                target="_blank"
                rel="noreferrer"
                aria-label="Clique para acessar o nosso linkedin."
              >
                <Img src={IcLikedin} alt="" width="32px" />
              </a>
              <a href="https://www.instagram.com/animaubrasil/" target="_blank" rel="noreferrer" aria-label="Clique para acessar o nosso instagram.">
                <Img src={IcInstagram} alt="" width="32px" />
              </a>
              <a
                href="https://www.facebook.com/animAUBrasil"
                target="_blank"
                rel="noreferrer"
                aria-label="Clique para acessar o nosso facebook."
              >
                <Img src={IcFacebook} alt="" width="32px" />
              </a>
            </GroupImg>
          </WrapperSocialMedias>
        </ContentFooter>
      </Footer>
      <SubFooter>
        <ContentSubFooter>
          <span>
            IPETIS AGENCIA DE PET SHOPS ONLINE LTDA - CNPJ 35.317.677/0001-32 - Endereço: Rua dos
            Guajajaras, 910 - Centro - Belo Horizonte - MG - CEP: 30180-106 ⦁ Copyright© ⦁ Todos os
            direitos reservados
          </span>
        </ContentSubFooter>
      </SubFooter>
    </>
  );
}

export default About;

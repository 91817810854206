import styled from 'styled-components';

import { FaAngleDown, FaAngleRight } from 'react-icons/fa';

export const Item = styled.div`
  height: 100%;
  max-height: ${({ active, height }) => (active ? height : '120px')};
  max-width: 600px;
  width: 100%;
  background-color: #381c4f;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 1.7rem;
  margin-bottom: 100px;
  margin-right: 20px;
  cursor: pointer;

  @media screen and (max-width: 1330px) {
    max-width: 470px;
  }

  @media screen and (max-width: 768px) {
    max-height: ${({ active, height }) =>
      active
        ? height.split('p')[0] > 400
          ? `calc(${height} + 240px)`
          : `calc(${height} + 180px)`
        : '300px'};
    margin-bottom: ${({ active }) => (active ? '50px' : '20px')};
    grid-template-columns: 1fr;
    padding: 0px;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 20px;
    margin-top: 10x;
  }
`;

export const ItemTitle = styled.div`
  color: #fcd36c;
  margin-left: 8px;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.3;
  max-width: 1250px;

  @media screen and (max-width: 768px) {
  }
`;

export const IconArrowDown = styled(FaAngleDown)`
  color: #fcd36c;
  margin-left: 1rem;
  font-size: 22px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 10px;
  }
`;

export const IconArrowRight = styled(FaAngleRight)`
  color: #fcd36c;
  margin-left: 1rem;
  font-size: 22px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 10px;
  }
`;

export const ItemBody = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    padding: 0px 1rem;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const ItemContent = styled.div`
  color: #fff;
  margin-left: 8px;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.0015em;
  font-weight: normal;
  font-family: Roboto;
  p {
    margin: 0.8rem 0;
  }

  li {
    margin-left: 24px;
    list-style: circle;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
  }
`;

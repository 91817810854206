import styled from 'styled-components';
import Sticker from '../../../assets/icons/sticker.png';
import MainImage from '../../../assets/images/main.png';

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  background-image: url(${MainImage});
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
`;

export const Info = styled.div`
  width: 540px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
`;

export const Title = styled.div`
  padding: 10px;
  p {
    color: white;
    font-weight: normal;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1;
  }
  margin-bottom: 20px;

  @media screen and (min-width: 430px) {
    p {
      font-size: 3.75rem;
    }
  }
`;

export const ContentImg = styled.div`
  padding: 10px;
  margin-bottom: 50px;
  width: 100%;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const Form = styled.div`
  color: white;
  background: #381c4f;
  width: 633px;
  height: 100%;
  border-radius: 16px;
  padding: 32px;
  margin-left: 2rem;
  
  li {
    padding-left: 0.2rem;
    margin-bottom: 1rem;
    font-size: 20px;
  }

  li ::before {
    margin-left: -33px;
    margin-top: 18px;
    content: url(${Sticker});
    position: absolute;
  }

  @media screen and (max-width: 670px) {
    margin-left: 0;
  }
`;

export const FormTitle = styled.div`
  padding: 10px;

  color: #fff;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2rem;
  line-height: 1.1;
  max-width: 1250px;

  margin-bottom: 20px;

  @media screen and (min-width:780px) {
    font-size: 2.75rem;
  }
`;
export const Text = styled.ul`
  padding: 0 1.8rem;
  line-height: 1.75rem;
  letter-spacing: 0.0015em;
  span {
    margin-top: 40px;
  }
`;

export const SubText = styled.ul`
  margin-top: 20px;
`;

export const Box = styled.div`
  margin-top: 20px;
  span {
    margin-top: 40px;
  }
`;

export const VideoContent = styled.div`
  padding: 10px;
  width: 600px;

  @media screen and (max-width: 670px) {
    width: 100%;
  }

  p {
    color: #fff;
    font-size: 1.8rem;
    margin: 1rem 0;
    font-weight: 300;
    line-height: 1;
  }
`

import React from 'react';

import { Container, Title, Content, Grid, Item, ItemImg, ItemTitle, ItemText } from './styles';

import icShoppingBag from '../../../assets/icons/about/ic-shopping-bag.png';
import icBox from '../../../assets/icons/about/box-2-1.png';
import icServico from '../../../assets/icons/about/ic-servico.png';
import icCalendar from '../../../assets/icons/about/ic-calendar.png';
import icDiscount from '../../../assets/icons/about/ic-discount.png';
import icDeliveryTruck from '../../../assets/icons/about/ic-delivery-truck.png';
import icStarMiddle from '../../../assets/icons/about/ic-star-middle.png';
import icCreditCard from '../../../assets/icons/about/ic-credit-card.png';

function About() {
  return (
    <Container id="about-section">
      <Content>
        <Title>
          Tenha a sua loja na animAU e aumente as suas vendas de produtos e serviços online.
        </Title>
        <Grid>
          <Item>
            <ItemImg src={icShoppingBag} alt="Ícone shopping" width="32" height="32"/>
            <ItemTitle>
              Potencialize suas vendas no 2º maior mercado do mundo no universo pets.
            </ItemTitle>
            <ItemText>
              O Brasil é o vice líder no mercado mundial do segmento Pets e corresponde a um volume
              de negócios acima dos R$ 20 Bilhões.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icBox} alt="Ícone caixa" width="32" height="32"/>
            <ItemTitle>Venda os seus produtos e serviços online</ItemTitle>
            <ItemText>
              Se você vende produtos ou serviços, a nossa plataforma de e-commerce fará a venda por
              você.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icServico} alt="Ícone serviço" width="32" height="32"/>
            <ItemTitle>
              Pertinho dos seus clientes. Com a animAU você atende mais e melhor a sua região!
            </ItemTitle>
            <ItemText>
              Sua Petshop aparece primeiro para os clientes mais próximos, com toda comodidade na
              palma da mão.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icCalendar} alt="Ícone calendar" width="32" height="32"/>
            <ItemTitle>Agendamento online</ItemTitle>
            <ItemText>
              Temos uma agenda exclusiva com os seus serviços contratados. Além disso, você pode
              reservar horários para os serviços já agendados fora da animAU.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icDiscount} alt="Ícone descontos" width="32" height="32"/>
            <ItemTitle>Faça promoções</ItemTitle>
            <ItemText>
              Na animAU você coloca seus produtos e serviços em promoção de um jeito simples e
              descomplicado.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icDeliveryTruck} alt="Ícone delivery" width="32" height="32"/>
            <ItemTitle>Frete</ItemTitle>
            <ItemText>
              Se sua Petshop terceiriza o serviço de frete ou utiliza frete próprio, não tem
              problema. A animAU incluirá o serviço de frete para as suas entregas.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icStarMiddle} alt="Ícone estrela" width="32" height="32"/>
            <ItemTitle>Avaliações</ItemTitle>
            <ItemText>
              Todos os seus clientes avaliam a petshop, podendo deixar um feedback contando como foi
              sua experiência na compra de produtos e serviços da sua loja.
            </ItemText>
          </Item>
          <Item>
            <ItemImg src={icCreditCard} alt="Ícone cartão" width="32" height="32"/>
            <ItemTitle>Recebimento das vendas</ItemTitle>
            <ItemText>
            O valor da sua venda será disponibilizado em até 30 dias. A animAU cobra uma taxa sobre a venda realizada, 
            já incluída a taxa do pagamento on line. Vide tabela abaixo em "Perguntas Frequentes".
            </ItemText>
          </Item>
        </Grid>
      </Content>
    </Container>
  );
}

export default About;

import styled from 'styled-components';

import AboutImage from '../../../assets/images/about.png';

export const Container = styled.section`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding: 20px 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;

  background-image: url(${AboutImage});
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 32px;
`;

export const Title = styled.div`
  color: #fff;
  margin-left: 16px;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-size: 3rem;
  line-height: 1.1;
  max-width: 1250px;

  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    font-weight: 800;
    margin-left: 0px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    padding: 0px;
  }
`;
export const Item = styled.div`
  height: 100%;
  max-width: 300px;
  margin-top: 20px;
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 600px;
    margin-left: 0px;
    &:first-child {
      margin-top: 0px;
    }
  }
`;

export const ItemImg = styled.img`
  margin: 0px 0px 5px 0px;
  width: 28Wpx;
  height: auto;
`;

export const ItemTitle = styled.div`
  color: #fff;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.2rem;
  line-height: 1.1;
`;
export const ItemText = styled.div`
  margin-top: 10px;
  color: #d9cce3;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 28px;
  line-height: 1.75rem;
  letter-spacing: 0.0015em;
  font-weight: 400;
`;

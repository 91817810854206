// Libs
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { navigate } from 'gatsby';
import HttpService from '../../../../services/HttpService';
import signInSchema from './ValidationSchema';
import maskPhoneNumber from '../../../../utils/maskPhoneNumber';
import maskCEP from '../../../../utils/maskCEP';
// Components
import InputNormal from '../../../Inputs/InputNormal';
import Button from '../../../Button';
import Select from '../../../Select';
// Services
import PetshopService from '../../../../services/Petshop';

// Styles
import { Row } from './styles';
import maskCpfAndCnpj from '../../../../utils/maskCpfAndCnpj';
import LoadingSpinner from '../../../LoadingSpinner';

const category = [
  {
    id: 1,
    value: 'Pet Shop',
    type: 'petshop',
  },
  {
    id: 2,
    value: 'Clínica Veterinária',
    type: 'veterinary',
  },
  {
    id: 3,
    value: 'Pet Shop e Clínica Veterinária',
    type: 'petshop_veterinary',
  },
  {
    id: 4,
    value: 'Prestador de Serviço Pet',
    type: 'provider_services',
  },
];

const category_type = [
  { id: 'PF', value: 'Pessoa Fisica PF' },
  { id: 'MEI', value: 'Microempreendedor Individual (MEI)' },
  { id: 'EI', value: 'Empresa individual (EI)' },
  { id: 'EIRELI', value: 'Empresa Individual de Responsabilidade Limitada (EIRELI)' },
  { id: 'LTDA', value: 'Sociedade Empresarial Limitada (LTDA)' },
  { id: 'SA', value: 'Sociedade anónima (SA)' },
];

export default function FormGroup() {
  const [isCnpj, setIsCnpj] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAddressViaCep = (values, setFieldValue, setErrors) => {
    const newCep = values.cep.replace('-', '');
    const httpService = new HttpService('https://viacep.com.br/');

    httpService
      .get(`ws/${newCep}/json/`)
      .then(resp => {
        if (resp.data.erro) {
          setErrors({ cep: 'Cep inválido' });
          return;
        }

        setFieldValue('state', resp.data.uf);
        setFieldValue('city', resp.data.localidade);
        setFieldValue('neighborhood', resp.data.bairro);
        setFieldValue('street', resp.data.logradouro);
      })
      .catch(() => {
        setErrors({ cep: 'Cep inválido' });
      });
  };

  const checkDocument = async (value, setFieldValue) => {
    const petshopService = new PetshopService();
    const body = { document: value, type: 'web' };
    try {
      await petshopService.checkDocument(body);
      setFieldValue('CPF/CNPJ unico', true);
    } catch (error) {
      setFieldValue('CPF/CNPJ unico', false);
    }
  };

  const getCheckEmail = async (values, setErrors, setFieldError, setFieldValue) => {
    const petshopService = new PetshopService();

    const body = { email: values.email, type: 'web' };
    petshopService
      .checkEmail(body)
      .then(resp => {
        setFieldValue('exists', true);
        toast.error(`Error no email! ${resp?.data?.message && resp.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(error => {
        setFieldValue('exists', false);

        return error;
      });
  };

  const getDataByCNPJ = async values => {
    const data = {
      user: {
        email: values.email,
        password: '',
        password_confirmation: '',
      },
      petshop: {
        document: values.legal_cnpj
          .replace('-', '')
          .replace('/', '')
          .replace('.', '')
          .replace('.', ''),
        telephone: values.telephone,
        legal_responsible: values.legal_responsible,
        category: category.filter(c => c.value === values.category)[0].type,
        company_type:
          values.legal_cnpj.length === 14
            ? category_type.filter(c => c.value === values.category_type)[0].id
            : category_type[0].id,
      },
      address: {
        cep: values.cep,
        street: values.street,
        city: values.city,
        state: values.state,
        complement: '',
        neighborhood: values.neighborhood,
      },
    };

    if (data.address.state === 'MG') {
      window.sessionStorage.setItem('@iPetis:user', JSON.stringify(data));
      navigate('/cadastro-petshop/dados-acesso');
    } else {
      navigate('/cadastro-petshop/erro-alcance');
    }
  };

  const handleSubmitForm = values => {
    setLoading(true);
    getDataByCNPJ(values);
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <Formik
        enableReinitialize
        initialValues={{
          legal_responsible: '',
          legal_cnpj: '',
          email: '',
          exists: false,
          telephone: '',
          cep: '',
          state: '',
          city: '',
          neighborhood: '',
          street: '',
          category: '',
          category_type: '',
        }}
        validationSchema={signInSchema}
        onSubmit={values => {
          handleSubmitForm(values);
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue, setErrors, setFieldError }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <InputNormal
                onChange={e => setFieldValue('legal_responsible', e.target.value)}
                value={values.legal_responsible && values.legal_responsible}
                height="75px"
                label="Nome Completo"
                placeholder="Qual o nome do responsável da petshop?"
                type_page="main"
                error={errors && errors.legal_responsible}
              />
            </Row>
            <Row>
              <InputNormal
                height="75px"
                label="E-mail"
                placeholder="Qual o email de contato da petshop?"
                type_page="main"
                onChange={e => setFieldValue('email', e.target.value.toLowerCase())}
                onBlur={() => getCheckEmail(values, setErrors, setFieldError, setFieldValue)}
                value={values.email && values.email}
                error={errors && errors.email}
              />
            </Row>

            <Row>
              <InputNormal
                onChange={e => {
                  const parsedValue = e.target.value.replace(/\D/g, '');
                  if (parsedValue.length >= 15) {
                    return;
                  }

                  if (parsedValue.length === 14) {
                    setIsCnpj(true);
                    setErrors('isCNPJ', true);
                  } else {
                    setIsCnpj(false);
                    setErrors('isCNPJ', false);
                  }
                  setFieldValue('legal_cnpj', parsedValue);
                }}
                onBlur={() => checkDocument(values.legal_cnpj.replace(/\D/g, ''), setFieldValue)}
                value={values.legal_cnpj && maskCpfAndCnpj(values.legal_cnpj)}
                height="75px"
                label="CPF/CNPJ"
                placeholder="Qual o CNPJ da petshop?"
                type_page="main"
                error={errors && errors.legal_cnpj}
              />
              <Select
                height="75px"
                label="Categoria"
                options={category}
                value={values.category}
                onChange={e => {
                  setFieldValue('category', e.target.value);
                }}
                error={errors && errors.category}
              />
            </Row>
            {isCnpj ? (
              <Row>
                <Select
                  height="75px"
                  label="Tipo de empresa"
                  options={category_type.filter((el, ind) => ind !== 0)}
                  value={values.category_type}
                  onChange={e => {
                    setFieldValue('category_type', e.target.value);
                  }}
                  error={isCnpj && errors ? errors.category_type : undefined}
                />
              </Row>
            ) : null}
            <Row>
              <InputNormal
                height="75px"
                label="Celular com DDD"
                placeholder="Qual o celular de contato da petshop?"
                type_page="main"
                onChange={e => setFieldValue('telephone', maskPhoneNumber(e.target.value))}
                value={values.telephone && maskPhoneNumber(values.telephone)}
                error={errors && errors.telephone}
              />
              <InputNormal
                height="75px"
                label="CEP"
                placeholder="CEP"
                type_page="main"
                onChange={e => {
                  if (e.target.value.length <= 9) {
                    setFieldValue('cep', e.target.value);
                  }
                }}
                value={values.cep && maskCEP(values.cep)}
                onBlur={() => getAddressViaCep(values, setFieldValue, setErrors)}
                error={errors && errors.cep}
              />
            </Row>
            <Row>
              <InputNormal
                height="75px"
                label="Estado"
                placeholder="Ex: MG"
                type_page="main"
                onChange={e => setFieldValue('state', e.target.value)}
                value={values.state && values.state}
                error={errors && errors.state}
              />
              <InputNormal
                height="75px"
                label="Cidade"
                placeholder="Ex: Belo Horizonte"
                type_page="main"
                onChange={e => setFieldValue('city', e.target.value)}
                value={values.city && values.city}
                error={errors && errors.city}
              />
            </Row>
            <Row>
              <Button
                height={54}
                styling="landingPageRegister"
                type="submit"
                // onClick={e => {
                //   e.preventDefault();

                // }}
              >
                Começar cadastro
              </Button>
              {/* <button type="button" onClick={e=>console.log(errors)}>teste</button> */}
            </Row>
          </Form>
        )}
      </Formik>
      {loading && <LoadingSpinner />}
    </>
  );
}

import * as yup from 'yup';
import { validaCpfCnpj, isCnpj } from '../../../../utils/cpfCnpjValidator';


const signInSchema = yup.object().shape({
  legal_responsible: yup.string().required('Nome obrigatório'),
  email: yup
    .string()
    .email('E-mail inválido')
    .required('E-mail obrigatório')
    .when('exists', (exists, schema) => {
      return schema.test('exists ', 'Já existe uma conta com este E-mail!', () => {
        return !exists;
      });
    }),
  telephone: yup
    .string()
    .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Número de telefone inválido')
    .required('Telefone de contato obrigatório.'),
  cep: yup.string().required('CEP obrigatório'),
  state: yup.string().required('Estado obrigatório').max(2, 'Digite apenas a sigla de seu estado!'),
  city: yup.string().required('Cidade obrigatório'),
  category: yup.string().required('Categoria obrigatório'),
  legal_cnpj: yup
    .string()
    .required('CPF/CNPJ Obrigatorio')
    .when('CPF/CNPJ unico', (exists, schema) => {
      return schema.test('CPF/CNPJ unico ', 'Já existe uma conta com este documento!', () => {
        return !exists;
      });
    })
    .test('end_time_test', 'CPF/CNPJ inválido.', function (value) {
      if (value === undefined) {
        return;
      }
      const validate = validaCpfCnpj(value);
      return validate;
    }),
  category_type: yup.string().when('legal_cnpj', {
    is: legal_cnpj => {
      const valid = isCnpj(legal_cnpj)
      return valid
    },
    then: yup.string().required('Campo Obrigatório.'),
    otherwise: yup.string().notRequired()
  }),
});

export default signInSchema;

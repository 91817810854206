import React, { useState } from 'react';
import SEO from '../components/seo';

import Layout from '../components/layout';
import Header from '../components/Header';
import Sidebar from '../components/Header/Sidebar';
import SectionMain from '../components/Section/Main';
import SectionAbout from '../components/Section/About';
import SectionDownload from '../components/Section/Download';
import SectionCommonQuestions from '../components/Section/CommonQuestions';
import Footer from '../components/Section/Footer';
import ButtonRegister from '../components/ButtonRegister';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'


const IndexPage = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const description =
    'Tenha sua loja na animAU e aumente as suas vendas de produtos e serviços online. Tenha a melhor experiência de compra de produtos e serviços para o seu melhor amigo!';
  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };
  return (
    <>
      <SEO
        title=""
        description={description}
        keywords={[
          `dog`,
          `cat`,
          `gato`,
          `cachorro`,
          `pedigree`,
          `brasil`,
          `petlove`,
          `online`,
          `app`,
          `ifood`,
          `apppet`,
          `animau`,
          `pets`,
          `ipet`,
          `ipetiz`,
          `doghero`,
          `pet`,
          `animal`,
          `petz`,
          `ipets`,
          `shop`,
          `petshop`,
          `ofertas`,
          `delivery`,
          `rápido`,
          `serviços`,
          `banho`,
          `tosa`,
          `loja`,
          `adestramento`,
          `marketeplace`,
          `veterinario`,
          `ipetis`
        ]}
      />
      <Layout>
        <Header toggle={toggleSidebar} />
        <main>
          <Sidebar isOpen={sidebarIsOpen} toggle={toggleSidebar} />
          <SectionMain />
          <SectionAbout />
          <ButtonRegister />
          <SectionDownload />
          <SectionCommonQuestions />
          <ButtonRegister />
          
          <Freshchat
            token={'03c69b1e-6a70-4e8f-9380-d56ba7a5df8f'}
            label="Suporte"
            ic_styles={{
              backgroundColor: '#381c4f',
              color: '#fff'
            }}
          />
        </main>
        <Footer />
        
      </Layout>
    </>
  );
};

export default IndexPage;

import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  div + div {
    margin-left: 8px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
    div + div {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;
